import React from "react"
import AnimatedSvg from "./animatedSvg"

const TwoColumnBlock = ({ data }) => {
  if (!data.primary) {
    data.primary = data
  }

  function getColumnOrderClass() {
    if (data.primary.column_order) {
      return data.primary.column_order === "Right Image"
        ? "image-right"
        : "image-left"
    } else {
      return "no-order"
    }
  }

  return (
    <div
      className={`page-data-slice two-column-block ${getColumnOrderClass()}`}
    >
      <div className="row">
        <div className="col text" data-sal="slide-up" data-sal-delay="300">
          <div
            dangerouslySetInnerHTML={{
              __html: data.primary.text.html,
            }}
          ></div>
        </div>

        <div className="col asset">
          {data.primary.animation_key && (
            <AnimatedSvg
              src={data.primary.image.url}
              animationKey={data.primary.animation_key}
            />
          )}

          {!data.primary.animation_key && (
            <img src={data.primary.image.fluid.src} alt="" />
          )}
        </div>
      </div>
    </div>
  )
}

export default TwoColumnBlock
