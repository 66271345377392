import React from "react"
import Modal from "react-modal"
import HubspotForm from "react-hubspot-form"

const HeroGatedDownloadLink = ({ data }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    Modal.setAppElement("#___gatsby")
  })

  const openFormModal = () => {
    setIsOpen(true)
  }

  const closeFormModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <div
        className="page-data-slice hero-gated-download-link"
        data-sal="slide-up"
        data-sal-delay="300"
      >
        {data.primary.callout_text.text && (
          <h3
            dangerouslySetInnerHTML={{ __html: data.primary.callout_text.text }}
          ></h3>
        )}

        <button
          className="button rounded violet"
          onClick={() => openFormModal()}
        >
          {data.primary.cta_text.text}
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeFormModal}
        contentLabel="Gated Download Form"
        className="modal hubspot-form"
        overlayClassName="modal-overlay dark"
      >
        <div className="form">
          <button onClick={() => closeFormModal()} className="close">
            Close
          </button>

          {data.primary.form_instructions && (
            <h2
              dangerouslySetInnerHTML={{
                __html: data.primary.form_instructions.text,
              }}
            ></h2>
          )}

          <HubspotForm
            portalId={process.env.GATSBY_REQUEST_DEMO_HUBSPOT_FORM_PORTAL_ID}
            formId={data.primary.hubspot_form_id}
            loading={<div>Loading..</div>}
            css=""
          />
        </div>
      </Modal>
    </>
  )
}

export default HeroGatedDownloadLink
