import React from "react"

import CustomLink from "../../customLink"

const DownloadEndCapTwoColumn = ({ data }) => {
  return (
    <div
      className="page-data-slice download-end-cap-two-column"
      data-sal="slide-up"
      data-sal-delay="300"
    >
      <div className="col">
        <div
          dangerouslySetInnerHTML={{
            __html: data.primary.download_column_title.html,
          }}
        ></div>
        <CustomLink
          className="button rounded violet"
          link={data.primary.download_column_link}
        >
          {data.primary.download_column_link_text?.text
            ? data.primary.download_column_link_text.text
            : "Download"}
        </CustomLink>
      </div>

      <div className="col">
        <h3>{data.primary.other_column_title.text}</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: data.primary.other_column_text.html,
          }}
        ></div>
      </div>
    </div>
  )
}

export default DownloadEndCapTwoColumn
