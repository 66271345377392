import React from "react"

const Footer = () => {
  return (
    <footer>
      <div className="inner">
        <img src="/logo-square-dark-blue.svg" alt="VergeSense" width="50" />
        <p>
          <a href="mailto:hello@vergesense.com">hello@vergesense.com</a>
        </p>
        <p>+1 (617) 618 5006</p>
        <p>
          &copy;{new Date().getFullYear()}. All rights reserved.{" "}
          <a href="https://vergesense.com/legal/VergeSense,%20Inc.%20-%20Privacy%20Policy.pdf">
            Privacy Policy
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
