import React from "react"
import AnimatedSvg from "./animatedSvg"

const SideBySide = ({ data }) => {
  return (
    <div className="page-data-slice side-by-side">
      <div className="row">
        <div className="col" data-sal="slide-up" data-sal-delay="300">
          <div
            dangerouslySetInnerHTML={{
              __html: data.primary.block_one_text.html,
            }}
          ></div>

          {data.primary.block_one_animation_key && (
            <AnimatedSvg
              src={data.primary.block_one_image.url}
              animationKey={data.primary.block_one_animation_key}
            />
          )}

          {!data.primary.block_one_animation_key && (
            <img src={data.primary.block_one_image.fluid.src} alt="" />
          )}
        </div>

        <div className="col" data-sal="slide-up" data-sal-delay="600">
          {data.primary.block_two_animation_key && (
            <AnimatedSvg
              src={data.primary.block_two_image.url}
              animationKey={data.primary.block_two_animation_key}
            />
          )}

          {!data.primary.block_two_animation_key && (
            <img src={data.primary.block_two_image.fluid.src} alt="" />
          )}

          <div
            dangerouslySetInnerHTML={{
              __html: data.primary.block_two_text.html,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default SideBySide
