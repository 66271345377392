import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../page-data/layout"
import SEO from "../seo"
import TextBlockTitle from "../page-data/slices/textBlockTitle"
import TwoColumnBlock from "../page-data/slices/twoColumnBlock"
import SideBySide from "../page-data/slices/sideBySide"
import EmailForm from "../page-data/slices/emailForm"
import ThreeByThreeFindings from "../page-data/slices/threeByThreeFindings"
import LargeCardTwoColumn from "../page-data/slices/largeCardTwoColumn"
import CenteredText from "../page-data/slices/centeredText"
import DownloadEndCapTwoColumn from "../page-data/slices/downloadEndCapTwoColumn"
import HeroGatedDownloadLink from "../page-data/slices/heroGatedDownloadLink"
import CustomLink from "../customLink"

const bodySlicesToSkip = [
  "PrismicDataPageBody3by3FindingsGrid",
  "PrismicDataPageBodyGatedDownloadLink",
]

const PageData = ({ data }) => {
  data = data.page_data.data

  function hasHeroLink() {
    return (
      data.hero_link &&
      data.hero_link_text &&
      data.hero_link_text.text &&
      !hasHeroGatedDownloadLink()
    )
  }

  function hasHeroGatedDownloadLink() {
    const slice = data.body.find(
      s => s.__typename === "PrismicDataPageBodyGatedDownloadLink"
    )

    if (!slice) {
      return false
    } else {
      return (
        slice.primary.hubspot_form_id !== "" &&
        slice.primary.cta_text &&
        slice.primary.cta_text.text !== ""
      )
    }
  }

  function quickRenderSlice(sliceName) {
    const slice = data.body.find(s => s.__typename === sliceName)
    return renderSlice(slice, Math.random(), [])
  }

  function getSeoPageTitle() {
    if (data.seo_page_title && data.seo_page_title.text) {
      return data.seo_page_title.text
    }

    if (data.hero_title && data.hero_title.text) {
      return data.hero_title.text
    }

    return "Data"
  }

  function getSeoPageDescription() {
    if (data.seo_page_description && data.seo_page_description.text) {
      return data.seo_page_description.text
    }

    return "A data-driven approach to understand the evolution of the workplace"
  }

  function getSeoPageImage() {
    if (data.seo_page_image && data.seo_page_image.url) {
      return data.seo_page_image.url
    }

    return null
  }

  return (
    <Layout hellobar={data.hellobar.html ? data.hellobar : null}>
      <SEO
        title={getSeoPageTitle()}
        description={getSeoPageDescription()}
        meta={[{ property: "og:image", content: getSeoPageImage() }]}
        bodyClass="page-data"
      />

      <section className="page-data-hero">
        <div className="inner">
          <div className="row">
            <div className="col text" data-sal="slide-up" data-sal-delay="0">
              <h1>{data.hero_title.text}</h1>
              <h2>{data.hero_subtitle.text}</h2>
              <div
                className="rich-text"
                dangerouslySetInnerHTML={{ __html: data.hero_text.html }}
              ></div>

              {hasHeroGatedDownloadLink() &&
                quickRenderSlice("PrismicDataPageBodyGatedDownloadLink")}

              {hasHeroLink() && (
                <CustomLink
                  link={data.hero_link}
                  className="button rounded violet"
                >
                  {data.hero_link_text.text}
                </CustomLink>
              )}
            </div>

            <div className="col asset" data-sal="slide-up" data-sal-delay="300">
              {data.hero_image.fluid && (
                <img src={data.hero_image.fluid.src} alt="" />
              )}

              {!data.hero_image.fluid &&
                quickRenderSlice("PrismicDataPageBody3by3FindingsGrid")}
            </div>
          </div>
        </div>
      </section>

      <section className="page-data-slices">
        <div className="inner">
          {data.body.map((slice, idx) => {
            return renderSlice(slice, idx, bodySlicesToSkip)
          })}
        </div>
      </section>

      <div className="end-spacer"></div>
    </Layout>
  )
}

export default PageData

function renderSlice(slice, idx, skip = []) {
  if (!slice) return null
  if (skip.includes(slice.__typename)) {
    return null
  }

  switch (slice.__typename) {
    case "PrismicDataPageBodyTextBlockTitle":
      return <TextBlockTitle data={slice} key={slice.id} idx={idx} />
    case "PrismicDataPageBodyTwoColumnBlock":
      return <TwoColumnBlock data={slice} key={slice.id} idx={idx} />
    case "PrismicDataPageBodySideBySide":
      return <SideBySide data={slice} key={slice.id} idx={idx} />
    case "PrismicDataPageBodyEmailForm":
      return <EmailForm data={slice} key={slice.id} idx={idx} />
    case "PrismicDataPageBody3by3FindingsGrid":
      return <ThreeByThreeFindings data={slice} key={slice.id} idx={idx} />
    case "PrismicDataPageBodyLargeCardTwoColumn":
      return <LargeCardTwoColumn data={slice} key={slice.id} idx={idx} />
    case "PrismicDataPageBodyCenteredText":
      return <CenteredText data={slice} key={slice.id} idx={idx} />
    case "PrismicDataPageBodyDownloadEndCapTwoColumn":
      return <DownloadEndCapTwoColumn data={slice} key={slice.id} idx={idx} />
    case "PrismicDataPageBodyGatedDownloadLink":
      return <HeroGatedDownloadLink data={slice} key={slice.id} idx={idx} />
    default:
      return null
  }
}

export const pageDataQuery = graphql`
  query PageDataQuery($uid: String!) {
    page_data: prismicDataPage(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      data {
        seo_page_title {
          text
        }
        seo_page_description {
          text
        }
        seo_page_image {
          url(imgixParams: { q: 90 })
        }
        hellobar {
          text
          html
        }
        hero_title {
          text
          html
        }
        hero_subtitle {
          text
          html
        }
        hero_text {
          text
          html
        }
        hero_image {
          fluid {
            src
          }
        }
        hero_link {
          url
          uid
          target
          type
          link_type
        }
        hero_link_text {
          text
          html
        }
        body {
          ... on PrismicDataPageBodyGatedDownloadLink {
            id
            primary {
              hubspot_form_id
              form_instructions {
                html
                text
              }
              cta_text {
                html
                text
              }
              callout_text {
                html
                text
              }
            }
          }
          ... on PrismicDataPageBodyTextBlockTitle {
            id
            primary {
              title {
                text
                html
              }
              text {
                text
                html
              }
            }
          }
          ... on PrismicDataPageBodyTwoColumnBlock {
            id
            primary {
              animation_key
              column_order
              text {
                text
                html
              }
              image {
                fluid {
                  src
                }
                url
              }
            }
          }
          ... on PrismicDataPageBodySideBySide {
            id
            primary {
              block_one_animation_key
              block_one_text {
                text
                html
              }
              block_one_image {
                fluid {
                  src
                }
              }
              block_two_animation_key
              block_two_text {
                text
                html
              }
              block_two_image {
                fluid {
                  src
                }
              }
            }
          }
          ... on PrismicDataPageBodyEmailForm {
            id
            primary {
              line_one {
                text
                html
              }
              line_two {
                text
                html
              }
            }
          }
          ... on PrismicDataPageBody3by3FindingsGrid {
            id
            primary {
              title {
                text
                html
              }
            }
            items {
              anchor_id
              icon {
                fluid {
                  src
                }
                url
              }
              text {
                text
                html
              }
            }
          }
          ... on PrismicDataPageBodyLargeCardTwoColumn {
            id
            primary {
              anchor_id
              icon {
                fluid {
                  src
                }
                url
              }
              title {
                text
                html
              }
            }
            items {
              animation_key
              image {
                fluid {
                  src
                }
                url
              }
              text {
                text
                html
              }
            }
          }
          ... on PrismicDataPageBodyCenteredText {
            id
            primary {
              text {
                text
                html
              }
            }
          }
          ... on PrismicDataPageBodyDownloadEndCapTwoColumn {
            id
            primary {
              download_column_title {
                text
                html
              }
              download_column_link {
                link_type
                url
                target
                type
              }
              download_column_link_text {
                text
                html
              }
              other_column_title {
                text
                html
              }
              other_column_text {
                text
                html
              }
            }
          }
        }
      }
    }
  }
`
