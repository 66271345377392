import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ mainClass, hellobar, children }) => {
  const data = useStaticQuery(graphql`
    query PageDataSiteGlobalQuery {
      site: site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {hellobar && (
        <div className="hello-bar">
          <div>
            <span dangerouslySetInnerHTML={{ __html: hellobar.html }}></span>
          </div>
        </div>
      )}
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className={mainClass}>{children}</main>
      <Footer siteTitle={data.site.siteMetadata.title} />
    </>
  )
}

Layout.propTypes = {
  mainClass: PropTypes.string,
  hellobar: PropTypes.object,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  mainClass: "",
}

export default Layout
