import React from "react"

const TextBlockTitle = ({ data }) => {
  return (
    <div
      className="page-data-slice text-block-title"
      data-sal="slide-up"
      data-sal-delay="300"
    >
      <h3>{data.primary.title.text}</h3>
      <p>{data.primary.text.text}</p>
    </div>
  )
}

export default TextBlockTitle
