import React from "react"

const ThreeByThreeFindings = ({ data, idx }) => {
  function scrollToTarget(item) {
    const target = document.querySelector(`#${item.anchor_id}`)

    if (target) {
      target.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <div className={`page-data-slice three-by-three-findings slice-${idx}`}>
      <h3>{data.primary.title.text}</h3>

      <div className="items">
        {data.items.map((item, idx) => {
          return (
            <div
              className={`item ${item.anchor_id ? "anchor-link" : ""}`}
              key={idx}
              onClick={() => scrollToTarget(item)}
              role="button"
            >
              {item.anchor_id && (
                <a href={`#${item.anchor_id}`}>
                  <img src={item.icon.url} alt="" /> <p>{item.text.text}</p>
                </a>
              )}

              {!item.anchor_id && (
                <span>
                  <img src={item.icon.url} alt="" /> <p>{item.text.text}</p>
                </span>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ThreeByThreeFindings
