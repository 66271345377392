import React from "react"
import HubspotForm from "react-hubspot-form"

const EmailForm = ({ data }) => {
  return (
    <div className="page-data-slice email-form">
      <div className="box" data-sal="slide-up" data-sal-delay="600">
        <h4 className="highlight-green">{data.primary.line_one.text}</h4>
        <h4>{data.primary.line_two.text}</h4>

        <HubspotForm
          portalId="8089267"
          formId="35316115-8f09-46ff-9f7c-a4a273601ced"
          // onSubmit={() => console.log("Submit!")}
          // onReady={form => console.log("Form Ready")}
          loading={<div>Loading..</div>}
          css=""
        />

        {/* <p>Legal line about signing up for email, privacy, etc.</p> */}
      </div>
    </div>
  )
}

export default EmailForm
