import React from "react"
import anime from "animejs/lib/anime.es.js"

class AnimatedSvg extends React.Component {
  constructor(props) {
    super(props)
    this.animationKey = props.animationKey
    this.svgSrc = props.src
    this.svgRef = React.createRef()
    this.state = {
      svgString: "",
    }
  }

  async componentDidMount() {
    const fetchSvg = async () => {
      fetch(this.svgSrc)
        .then(res => res.text())
        .then(svg => this.setState({ svgString: svg }))
    }

    await fetchSvg()

    setTimeout(() => {
      this.startAnimation()
    }, 1000)
  }

  // animation methods
  startAnimation() {
    // console.log(this.animationKey)
    switch (this.animationKey) {
      case "buildings":
        this.animateBuildings()
        break
      case "bar-stats":
        this.animateBarStats()
        break
      case "calendars":
        this.animateCalendars()
        break
      case "circle-stats":
        this.animateCircleStats()
        break
      case "triangle":
        this.animateTriangle()
        break
      case "bubbles":
        this.animateBubbles()
        break
      case "pie-chart":
        this.animatePieChart()
        break
      case "people":
        this.animatePeople()
        break
      case "single-building":
        this.animateSingleBuilding()
        break
      case "money":
        this.animateMoney()
        break
      case "map":
        this.animateMap()
        break
      case "complex-bars":
        this.animateComplexBars()
        break
      case "trophies":
        this.animateTrophies()
        break
      case "diversified-assets":
        this.animateDiversified()
        break
      default:
        break
    }
  }

  animateOnObserve(element, animation, threshold = 0.25) {
    const cb = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          animation.play()
        }
      })
    }

    const observer = new IntersectionObserver(cb, { threshold: threshold })
    observer.observe(element)
  }

  animateBuildings() {
    const buildingFills = this.svgRef.current.querySelectorAll(
      "rect[x][y][fill='#A788FF']"
    )

    const animation = anime({
      targets: buildingFills,
      height: {
        value: "-=116",
      },
      delay: anime.stagger(250),
      duration: 500,
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animateBarStats() {
    const bars = this.svgRef.current.querySelectorAll("rect")
    if (bars && bars[0]) {
      const barsParent = bars[0].parentElement

      const animation = anime({
        targets: Array.from(bars).reverse(),
        width: {
          value: "-=75px",
        },
        duration: 750,
        delay: anime.stagger(250),
        direction: "reverse",
        easing: "easeInOutSine",
        autoplay: false,
      })

      this.animateOnObserve(barsParent, animation)
    }
  }

  animateCalendars() {
    const calendars = this.svgRef.current.querySelectorAll("*[fill='#CAB9FB']")

    const animation = anime({
      targets: Array.from(calendars).reverse(),
      fill: "#DFDDD7",
      duration: 250,
      delay: anime.stagger(250),
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animateCircleStats() {
    const circles = this.svgRef.current.querySelectorAll(
      ".animated-svg.circle-stats ellipse"
    )

    const animation = anime({
      targets: circles,
      ry: {
        value: "-=15",
      },
      rx: {
        value: "-=15",
      },
      duration: 500,
      delay: anime.stagger(500),
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animateTriangle() {
    const arrow = this.svgRef.current.querySelectorAll(
      "path[fill='#F4F3EF'], path[fill='white']"
    )

    const animation = anime({
      targets: arrow,
      opacity: 0,
      translateY: "250px",
      duration: 1000,
      delay: anime.stagger(500),
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animateBubbles() {
    const bubbles = this.svgRef.current.querySelectorAll("circle, path")
    const animation = anime({
      targets: bubbles,
      opacity: 0,
      r: {
        value: "-=5",
      },
      duration: 250,
      delay: anime.stagger(250),
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animatePieChart() {
    const filled = this.svgRef.current.querySelector("path[fill='#7747FF']")
    const animation = anime({
      targets: filled,
      d: [{ value: "M141 0C141 0 141 0 140.5 0V140.5L141 0Z" }],
      duration: 1000,
      delay: 0,
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animatePeople() {
    const people = this.svgRef.current.querySelectorAll("path")
    const animation = anime({
      targets: people,
      translateY: "-300px",
      duration: 250,
      delay: anime.stagger(250),
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animateSingleBuilding() {
    const fill = this.svgRef.current.querySelector("rect[fill='#7747FF']")
    const animation = anime({
      targets: fill,
      translateY: "220px",
      duration: 1000,
      delay: anime.stagger(250),
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animateMoney() {
    const circles = this.svgRef.current.querySelectorAll("*[fill='#7747FF']")
    const animation = anime({
      targets: Array.from(circles).reverse(),
      fill: "#DFDDD7",
      duration: 250,
      delay: anime.stagger(250),
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animateMap() {
    const people = this.svgRef.current.querySelectorAll(
      "*[fill='#7747FF'], path[fill='#0F0D30']"
    )
    const animation = anime({
      targets: Array.from(people).reverse(),
      opacity: 0,
      duration: 250,
      delay: anime.stagger(250),
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animateComplexBars() {
    const bars = this.svgRef.current.querySelectorAll(
      "rect[fill='#DFDDD7'], path[fill='#0F0D30']"
    )
    const animation = anime({
      targets: bars,
      translateY: "500px",
      fill: "#f4f3ef",
      duration: 500,
      delay: anime.stagger(250),
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animateTrophies() {
    const paths = this.svgRef.current.querySelectorAll("path")
    const animation = anime({
      targets: Array.from(paths),
      opacity: 0,
      translateY: "50px",
      fill: "#f4f3ef",
      duration: 100,
      delay: anime.stagger(150),
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  animateDiversified() {
    const paths = this.svgRef.current.querySelectorAll("path[fill='#0F0D30']")
    const animation = anime({
      targets: Array.from(paths).reverse(),
      opacity: 0,
      translateY: "50px",
      fill: "#f4f3ef",
      duration: 250,
      delay: anime.stagger(500),
      direction: "reverse",
      easing: "easeInOutSine",
      autoplay: false,
    })

    this.animateOnObserve(this.svgRef.current, animation)
  }

  render() {
    return (
      <div className={`animated-svg ${this.animationKey}`} ref={this.svgRef}>
        <div dangerouslySetInnerHTML={{ __html: this.state.svgString }}></div>
      </div>
    )
  }
}

export default AnimatedSvg
