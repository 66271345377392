import React from "react"
import TwoColumnBlock from "./twoColumnBlock"

const LargeCardTwoColumn = ({ data, idx }) => {
  return (
    <div
      className={`page-data-slice large-card-two-column slice-${idx}`}
      id={data.primary.anchor_id ? data.primary.anchor_id : ""}
    >
      <h3>
        <img src={data.primary.icon.url} alt="" />
        {data.primary.title.text}
      </h3>

      <div className="items">
        {data.items.map((item, idx) => {
          return <TwoColumnBlock data={item} key={idx} idx={idx} />
        })}
      </div>
    </div>
  )
}

export default LargeCardTwoColumn
