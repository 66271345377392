import React from "react"

const CenteredText = ({ data }) => {
  return (
    <div
      className="page-data-slice centered-text"
      data-sal="slide-up"
      data-sal-delay="300"
    >
      <div dangerouslySetInnerHTML={{ __html: data.primary.text.html }}></div>
    </div>
  )
}

export default CenteredText
