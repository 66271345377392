import React from "react"

const Header = () => {
  return (
    <header className="page-data">
      <a href="/">
        <img src="/logo-wide-dark-blue.svg" alt="VergeSense" />
      </a>

      <a href="/" className="button rounded violet">
        Visit VergeSense
      </a>
    </header>
  )
}

export default Header
